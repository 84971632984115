import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Section from '../../components/Common/Section'
import Container from '../../components/Common/Container'
import Typography from '../../components/Common/Typography'
import { Button } from '../../components/Common/Button'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import List from '../../components/Common/List'
import Form from '../../components/contact-form/Form'

import PersonDoubleIcon from '../../svg/person-double-icon'
import FrameCopyCodeIcon from '../../svg/frame-copy-code-icon'
import MobileModuleIcon from '../../svg/mobile-module-icon'
import EyeIcon from '../../svg/eye-icon'
import RubleIcon from '../../svg/ruble-icon'

import * as s from './Bim360Migrate.module.scss'

const Bim360Migrate = () => {
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="SIGNAL DOCS. Перенос данных из BIM 360 (Бим 360)"
        description="SIGNAL DOCS (Сигнал Докс) - качественная замена Autodesk BIM 360 и перенос данных. Ведение технического документооборота строительных проектов российского производства."
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        imageMobile={images.banner_mobile.childImageSharp.gatsbyImageData}
        classNameSubTitle={s.subTitle}
        title={`Переместите все ваши данные из Autodesk\n BIM 360/АСС в SIGNAL DOCS быстро и надежно`}
        subTitle={`Наша техподдержка работает без\u00A0выходных, поэтому\n миграция пройдет в самые короткие сроки`}
        colorShadow="dark"
        titleSize={40}
        isUppercase={false}
        imgStyle={{ height: '467px' }}
      >
        <AnchorLink
          to="/bim-360-migrate#demo-version"
          stripHash
          className={s.anchor_link}
        >
          <Button size="medium">Получить демо-версию</Button>
        </AnchorLink>
      </BannerSection>
      <ModuleSection2>
        <ModuleSectionItem2
          title="Какие данные переносим?"
          variantTitle="h2"
          description={`Мы перенесем все данные, необходимые для работы, с\u00A0сохранением вашей структуры и логики:`}
          columnLeftChildren={
            <div className={s.lists}>
              <List>
                <li>Папки</li>
                <li>Файлы</li>
                <li>Пользователей</li>
                <li>Роли</li>
                <li>Замечания с привязанными к ним пометками и маркапами</li>
                <li>Процессы согласований</li>
              </List>
            </div>
          }
          columnRightChildren={
            <div>
              <GatsbyImage
                image={images.image1.childImageSharp.gatsbyImageData}
                alt="Перенос данных"
              />
            </div>
          }
        />
        <ModuleSectionItem2
          title="Процесс переноса"
          variantTitle="h2"
          description={`Миграция данных осуществляется автоматизированным путем, с помощью написанных скриптов, под контролем технической поддержки. Это позволяет значительно ускорить процессы переноса данных.`}
          columnRightChildren={
            <div>
              <GatsbyImage
                image={images.image2.childImageSharp.gatsbyImageData}
                alt="Процесс переноса"
              />
            </div>
          }
          reverseElements
        />
      </ModuleSection2>
      <Section className={s.section} bgColor="dark">
        <GatsbyImage
          image={images.image4.childImageSharp.gatsbyImageData}
          alt={''}
          className={s.imageBg}
        />
        <div className={s.overlay} />

        <Container>
          <Typography variant="h2" color="blue" size={40}>
            Преимущества подключения к SIGNAL DOCS
          </Typography>
          <div className="flex flex-wrap" style={{ gap: 40, marginTop: 36 }}>
            <BenefitsConnectingSignalDocs
              title="Отзывчивая техническая поддержка"
              text={`Наша техподдержка доступна без\u00A0выходных. Пишите в телеграм или\u00A0на\u00A0почту. Ответим в течение 15 минут.`}
              Icon={<PersonDoubleIcon className={s.icon} />}
            />
            <BenefitsConnectingSignalDocs
              title="Регулярная доработка софта по вашим запросам"
              text="Внимательно относимся к вашей обратной связи и разрабатываем новый функционал под ваши потребности."
              Icon={<FrameCopyCodeIcon className={s.icon} />}
            />
            <BenefitsConnectingSignalDocs
              title="Удобная мобильная версия"
              text={`Получайте актуальную информацию о\u00A0документах и связанных с ними процессах прямо на стройплощадке в\u00A0мобильной версии SIGNAL DOCS.`}
              Icon={<MobileModuleIcon className={s.icon} />}
            />
            <BenefitsConnectingSignalDocs
              title="Вариативность просмотра документов и моделей"
              text={`Для работы с BIM-моделями у нас предусмотрены два web-просмотрщика: Forge и Tangl. А для просмотра PDF и\u00A0MS\u00A0Project мы создали свой встроенный просмотрщик — не понадобятся сторонние программы.`}
              Icon={<EyeIcon className={s.icon} />}
            />
            <BenefitsConnectingSignalDocs
              title="Устойчивая и выгодная цена"
              text={`Цена известна сразу — всего 10 000 ₽ за\u00A0лицензию в год! Также у нас есть\u00A0безлимитный тариф — 1 миллион в\u00A0год. Без ограничения по трафику и\u00A0проектам. Такие условия позволят подключить всех необходимых участников рабочего процесса без\u00A0больших вложений.`}
              Icon={<RubleIcon className={s.icon} />}
            />
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <Typography variant="h2" color="blue" size={40}>
            {`Перенос компании за\u00A048\u00A0часов: кейс Development Systems`}
          </Typography>
          <div className={s.review_cards}>
            <div className={s.review_cards_block}>
              <div className="flex items-center">
                <GatsbyImage
                  image={
                    images.Elizaveta_Usievich.childImageSharp.gatsbyImageData
                  }
                  title="Елизавета Усиевич"
                  alt="Елизавета Усиевич"
                />
                <div className={s.review_cards_info}>
                  <Typography
                    variant="body2"
                    color="white"
                    size={18}
                    style={{ fontWeight: 500 }}
                  >
                    Елизавета Усиевич
                  </Typography>
                  <Typography variant="body2" color="white">
                    {`BIM-координатор,\nDevelopment Systems`}
                  </Typography>
                </div>
              </div>
              <Typography variant="body1" color="white" size={18} mt={16}>
                {`Перешли с Autodesk BIM 360 / АСС из-за постоянных сбоев в работе и нестабильного ПО. Протестировали несколько СОДов и выбрали SIGNAL DOCS, потому что в нем лучшее из функционала Autodesk и\u00A0много своих полезных фишек. Например, понятный интерфейс помог сократить время на обучение сотрудников. Миграцию провели сами разработчики SIGNAL. В пятницу мы закончили рабочий день в Autodesk, а в понедельник получили готовую рабочую среду со всеми проектами в SIGNAL. Все технические вопросы по новому СОДу поддержка SIGNAL решала за несколько минут через телеграм-бота.`}
              </Typography>
            </div>
            <div className={s.review_cards_image}>
              <GatsbyImage
                image={images.image3.childImageSharp.gatsbyImageData}
                className={s.hiddenMobile}
                alt="Development Systems"
              />
              <GatsbyImage
                image={images.image3mobile.childImageSharp.gatsbyImageData}
                className={s.hiddenDesktop}
                alt="Development Systems"
              />
            </div>
          </div>
        </Container>
      </Section>
      <Section id="demo-version">
        <Container>
          <div className={s.form_block}>
            <div className={s.form_block_quote}>
              <Typography variant="h2" color="blue" size={32} mb={24}>
                Хотите перейти на новый СОД быстро и безболезненно?
                Подключайтесь к SIGNAL DOCS!
              </Typography>
              <Typography variant="body1" size={18} mt={16}>
                {`Оставьте заявку и наш специалист проведёт техническую консультацию, ответит на все интересующие вопросы. Вы\u00A0также сможете сами протестировать нашу систему с помощью демодоступа на 30 дней.`}
              </Typography>
            </div>
            <Form requestType="Demo" />
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Bim360Migrate

const BenefitsConnectingSignalDocs = ({
  title,
  text,
  Icon,
}: {
  title: string
  text: string
  Icon: JSX.Element
}) => {
  return (
    <div style={{ maxWidth: 360, width: '100%' }}>
      {Icon}
      <Typography variant="h3" color="blue" size={24} mt={16}>
        {title}
      </Typography>
      <Typography variant="body1" color="white" size={18} mt={16}>
        {text}
      </Typography>
    </div>
  )
}

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "bim-360-migrate/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    banner_mobile: file(
      relativePath: { eq: "bim-360-migrate/banner-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    imageBg: file(relativePath: { eq: "bim-360-migrate/image-bg.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image1: file(relativePath: { eq: "bim-360-migrate/image-1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 624, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "bim-360-migrate/image-2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 624, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "bim-360-migrate/image-3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 720, placeholder: BLURRED)
      }
    }
    image4: file(relativePath: { eq: "bim-360-migrate/image-4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1440, placeholder: BLURRED)
      }
    }
    image3mobile: file(
      relativePath: { eq: "bim-360-migrate/image-3-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 302, placeholder: BLURRED)
      }
    }
    Elizaveta_Usievich: file(
      relativePath: { eq: "bim-360-migrate/Elizaveta_Usievich.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 91, placeholder: BLURRED)
      }
    }
  }
`
